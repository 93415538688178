<template>
	<div style="background: #fff;padding: 15px;" v-if="productInfo">
		<div style="height: 100px;background: #F0F2F5;align-items: center;display: flex;margin-bottom: 30px;" v-if="productInfo">
			<div style="display: flex;margin-left: 10px;">
				<img v-if="productInfo.ImgUrlComplete" :src="productInfo.ImgUrlComplete" style="width: 80px;height: 80px;">
				<svg-icon v-else icon-class="noImgIcon" />
				<div style="margin-left: 15px;">
					<div style="display:flex;justify-content:space-between;align-items:center;margin-top: 5px;">
						<div style="margin-right: 40px;font-size: 14px;color: #606266;">
							<span style="color: #F56C6C;font-size: 12px;" v-if="productInfo.ProductType === 1">[组合]</span>
							{{productInfo.Name}}
						</div>
					</div>
					<div style="margin-top: 25px;color: #606266;font-size: 14px;">{{productInfo.ProductNo}}</div>
				</div>
			</div>
		</div>
		<div>
			<label class="label">关键字: </label>
			<el-input v-model="searchKey" size="mini" placeholder="门店名称、编码、地址" style="width: 200px;;margin-right: 20px;"></el-input>
			<label class="label">门店标签: </label>
			<el-select v-model="tagId" size="mini" placeholder="请选择" style="margin-right: 20px;">
				<el-option :key="-1" label="全部" :value="null">
				</el-option>
				<el-option v-for="item in tagList" :key="item.Id" :label="item.TagName" :value="item.Id">
				</el-option>
			</el-select>

			<label class="label">配送方式: </label>
			<el-select v-model="seedType" size="mini" placeholder="请选择" style="margin-right: 20px;">
				<el-option :key="-1" label="全部" :value="null">
				</el-option>
				<el-option :key="0" label="快递发货" :value="0">
				</el-option>
				<el-option :key="1" label="到店自提" :value="1">
				</el-option>
				<el-option :key="2" label="同城配送" :value="2">
				</el-option>
				<el-option :key="3" label="仅快递发货" :value="3"></el-option>
				<el-option :key="4" label="仅到店自提" :value="4"></el-option>
				<el-option :key="5" label="仅同城配送" :value="5"></el-option>
			</el-select>
			<el-button type="primary" style="margin-left:10px;width:90px;" size="small" @click="doorSearch">查询</el-button>
		</div>
		<div class="table-container" style="margin-top: 20px;">
			<el-table :data="productData" v-loading="loading" style="width: 100%;" ref="doorChose" @selection-change="productSelectChange">
				<el-table-column type="selection" width="50">
				</el-table-column>
				<el-table-column prop="ShopNo" label="门店编码"></el-table-column>
				<el-table-column prop="ShopName" label="门店名称" width="220px"></el-table-column>
				<el-table-column label="地址" width="250px">
					<template slot-scope="scope">
						<div style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">
							{{scope.row.Province+scope.row.City+scope.row.Area+scope.row.Address}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="ContactPhone" label="联系电话"></el-table-column>
				<el-table-column prop="ShopTagName" label="门店标签"></el-table-column>
				<el-table-column prop="BusinessStartTime" label="营业时间" width="150">
					<template slot-scope="scope">
						<div>{{scope.row.BusinessStartTime}}-{{scope.row.BusinessEndTime}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="创建时间"></el-table-column>
				<el-table-column label="快递发货">
					<template slot-scope='scope'>
						<div style="height: 22px;">

							<!-- <el-switch v-if="productInfo.ProductType === 1" style="display: block" :value="true" active-color="#53A8FF"
							 inactive-color="#DCDFE6">
							</el-switch> -->

							<!-- v-else -->
							<el-switch  style="display: block" @change="seedTypeChange(scope.row, 0)" v-model="scope.row.IsOpenExpress"
							 active-color="#53A8FF" inactive-color="#DCDFE6">
							</el-switch>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="到店自提">
					<template slot-scope='scope'>
						<div style="height: 22px;">
							<!-- <el-switch v-if="productInfo.ProductType === 1" style="display: block" :value="false" active-color="#53A8FF"
							 inactive-color="#DCDFE6" @change="noChangestate">
							</el-switch> -->
							<!-- v-else -->
							<el-switch  style="display: block" @change="seedTypeChange(scope.row, 1)" v-model="scope.row.IsOpenSelfExtract"
							 active-color="#53A8FF" inactive-color="#DCDFE6">
							</el-switch>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="同城配送">
					<template slot-scope='scope'>
						<div style="height: 22px;">
							<el-switch  style="display: block" @change="seedTypeChange(scope.row, 2)" v-model="scope.row.IsOpenInCityDelivery"
							 active-color="#53A8FF" inactive-color="#DCDFE6">
							</el-switch>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="90">
					<template slot-scope="scope">
						<el-button type="text" @click="eidtPrice(scope.row)">供货价</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;margin-top:20px">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
					<el-checkbox @change="tabCheckedChange" v-model="checked"></el-checkbox>
					<el-dropdown @command="handleCommand">
						<span style="margin-left: 20px;border: 1px solid #E5E9F2;padding: 5px 10px;cursor: pointer;">批量设置供货价</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="a">批量设置当前页选中</el-dropdown-item>
							<el-dropdown-item command="b">批量设置全部门店</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<!-- v-if="productInfo.ProductType===0" -->
					<el-dropdown @command="handleCommand">
						<span style="margin-left: 10px;border: 1px solid #E5E9F2;padding: 5px 10px;cursor: pointer;">批量设置配送方式</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="c">批量设置当前页选中</el-dropdown-item>
							<el-dropdown-item command="d">批量设置全部门店</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<!-- <span style="margin-left: 20px;border: 1px solid #E5E9F2;padding: 5px 10px;cursor: pointer;" @click="batchSettingPrice">批量设置供货价</span> -->
					<!-- <span style="margin-left: 10px;border: 1px solid #E5E9F2;padding: 5px 10px;cursor: pointer;" @click="batchSettingEmail"
					 v-if="productInfo.ProductType===0">批量设置配送方式</span> -->
				</div>
				<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="doorSizeChange" @current-change="doorCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>
		<!-- 商品供货价编辑的弹框 -->
		<el-dialog :visible.sync="stockVisble" width="550px" title="设置供货价" :before-close="Clearmualtion">
			<div style="margin-bottom: 20px;border-top: 1px solid #ccc;padding-top: 20px;">商品:<span style="color: #F56C6C;font-size: 12px;"
				 v-if="productInfo.ProductType === 1">[组合]</span>{{productInfo.Name}}</div>
			<el-table :data="specList" style="width: 100%;" v-if="!isNormalSpec">
				<el-table-column prop="SpecValue" :label="SpecTitle" width="100px"></el-table-column>
				<el-table-column prop="SpecValue2" :label="SpecTitle2" width="100px"></el-table-column>
				<el-table-column label="供货价">
					<template slot-scope="scope">
						<el-input v-model="scope.row.SupplyPrice" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
					</template>
				</el-table-column>
			</el-table>
			<el-form :model="ruleForm" ref="ruleForm" :rules="rules" v-if="isNormalSpec">
				<el-form-item label="供货价:" prop="singleprice">
					<el-input v-model="ruleForm.singleprice" style="width: 400px;" size="mini" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
				</el-form-item>
			</el-form>
			<div style="text-align: center;margin-top:30px;">
				<el-button type="primary" style="width: 120px;" @click='savePriceChange'>保存</el-button>
			</div>
		</el-dialog>

		<!-- 提示弹窗 -->
		<el-dialog :visible.sync="stopDialogShow" width="400px" center :show-close="false">
			<div style="line-height: 25px;">您正在修改{{isCurrentPage ? multipleSelection.length : Total}}家门店的商品{{emailVisble?'配送方式':'供货价'}}，是否确认继续修改？</div>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 120px;" @click="stopActive(0)">取 消</el-button>
				<el-button style="width: 120px;margin-left: 30px;" type="primary" @click="stopActive(1)">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 商品配送方式编辑的弹框 -->
		<el-dialog :visible.sync="emailVisble" width="550px" title="设置配送方式" @closed="emailData=[{email: false,self: false,city:false,}]">
			<div style="margin-bottom: 20px;border-top: 1px solid #ccc;padding-top: 20px;">商品:{{productInfo.Name}}</div>
			<el-table :data="emailData" style="width: 100%;">
				<el-table-column label="快递发货">
					<template slot-scope='scope'>
						<div style="height: 22px;">
							<el-switch style="display: block;line-height: 8px;" v-model="scope.row.email" active-color="#53A8FF"
							 inactive-color="#DCDFE6">
							</el-switch>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="到店自提">
					<template slot-scope='scope'>
						<div style="height: 22px;">
							<el-switch style="display: block" v-model="scope.row.self" active-color="#53A8FF" inactive-color="#DCDFE6">
							</el-switch>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="同城配送">
					<template slot-scope='scope'>
						<div style="height: 22px;">
							<el-switch style="display: block" v-model="scope.row.city" active-color="#53A8FF" inactive-color="#DCDFE6">
							</el-switch>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: center;margin-top:30px;">
				<el-button type="primary" style="width: 120px;" @click='saveSeedType'>保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		productbaseinfo,
		productshoplist,
		StoreTagList,
		shopproductsendtypeedit,
		setshopproductsendtype,
		productspecinfo,
		setshopproductspecsupplyprice
	} from '@/api/TurnTomySelf'
	import {
		mapGetters
	} from 'vuex'
	export default {
		data() {
			return {
				id: '',
				ruleForm: {
					name: '',
					code: '',
					singleprice: '',
					singledesc: ''
				},
				rules: {
					singleprice: {
						required: true,
						message: '请填写供货价',
						trigger: 'blur'
					}

				},
				emailData: [{
					email: false,
					self: false,
					city:false,
				}],
				stockData: [{
						stockName: "白色",
						stockValue: "s码",
						stock: ""
					},
					{
						stockName: "白色",
						stockValue: "s码",
						stock: ""
					},
					{
						stockName: "白色",
						stockValue: "s码",
						stock: ""
					}
				],
				multipleSelection: [],
				productInfo: null,
				productData: [],
				tagList: [],
				seedList: [],
				specList: [],
				searchKey: '',
				tagId: '', //门店标签
				seedType: '', //配送方式
				loading: false,
				editVisable: false,
				groupName: "",
				activeType: 0,
				currentPage: 1,
				pageSize: 10,
				Total: 1,
				emailVisble: false,
				stopDialogShow: false,
				stockVisble: false, //是否显示供货价弹窗
				checkedAll: false, //当前页全选
				checked: false, //是否 仅看可选商品
				addProductDialogShow: false,
				isCurrentAll: false,
				isCurrentPage: false,
				imageUrl: 'http://pic39.nipic.com/20140307/13928177_195158772185_2.jpg'
			};
		},
		computed: {
			isNormalSpec() {
				if (this.specList.length && this.specList[0].SpecValue) {
					return false;
				} else {
					return true
				}
			},
			...mapGetters([
				'ComponentId'
			]),
		},
		beforeMount() {
			this.id = this.$route.query.id;
			this.getList(); //门店列表
			this.getProductInfo(); //产品详细信息
			this.getTagList(); //门店标签列表

		},
		methods: {
			Clearmualtion() {
				this.stockVisble = false
				this.multipleSelection = []
			},
// 			noChangestate() {
// 				this.$message({showClose: true,
// 					type: 'error',
// 					message: '组合销售商品，配送方式默认快递发货，不支持修改'
// 				})
// 				return;
// 			},
			async getList() {

				this.loading = true;

				const res = await productshoplist({
					ProductId: this.id,
					KeyWords: this.searchKey,
					ShopTagId: this.tagId,
					SendType: this.seedType,
					Skip: (this.currentPage - 1) * this.pageSize,
					Take: this.pageSize
				})

				this.loading = false;

				if (res.IsSuccess) {
					this.productData = res.Result.Results;
					this.Total = res.Result.Total;
				}
			},
			async getProductInfo() {
				const res = await productbaseinfo({
					ProductId: this.id
				})

				if (res.IsSuccess) {
					this.productInfo = res.Result;
				}
			},
			async getTagList() {
				const res = await StoreTagList();

				if (res.IsSuccess) {
					this.tagList = res.Result;
				}
			},
			async getSpecList(id, index) {
				//单个设置
				if (index == 0) {
					const res = await productspecinfo({
						ProductId: this.id,
						ShopId: id
					});

					if (res.IsSuccess) {
						// this.specList = res.Result;
						// console.log(res,'88888888')
						// res.Result
						this.specList = res.Result.ProductSpecInfoDtos;
						this.SpecTitle = res.Result.SpecTitle
						this.SpecTitle2 = res.Result.SpecTitle2
						//只有一个
						if (this.specList.length == 1 && this.specList[0].SpecValue == '') {
							this.ruleForm.singleprice = this.specList[0].SupplyPrice
						}

					}
				} else {
					//批量获取
					const res = await productspecinfo({
						ProductId: id,
					});
					if (res.IsSuccess) {
						this.specList = res.Result.ProductSpecInfoDtos;
						this.SpecTitle = res.Result.SpecTitle
						this.SpecTitle2 = res.Result.SpecTitle2
					}
				}

			},
			doorSearch() {
				this.currentPage = 1;
				this.getList();
			},
			productSelectChange(val) {
				this.multipleSelection = val;
				this.isCurrentAll = val.length == this.productData.length ? true : false
				if (this.isCurrentAll) {
					this.checked = true
				} else {
					this.checked = false
				}
			},
			tabCheckedChange() {
				this.$refs['doorChose'].toggleAllSelection();
			},
			// 切换显示条数
			doorSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			doorCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},
			eidtPrice(row) {
				// this.ruleForm.singleprice = null
				this.getSpecList(row.Id, 0);
				this.isCurrentPage = true;
				this.multipleSelection = [row];
				this.stockVisble = true
				console.log(this.multipleSelection, '当前选中的')
			},
			stopBtn() {
				this.stopDialogShow = true
			},
			stopActive(type) {
				//点击取消
				if (type === 0) {
					this.stopDialogShow = false
					// this.multipleSelection = []
				} else {
					if (this.stockVisble) {
						this.sureSavePrice();
					} else {
						this.sureSaveSeedType();
					}
				}

			},
			// batchSettingEmail() {
			// 	this.emailVisble = true
			// },
			// batchSettingPrice() {
			// 	this.stockVisble = true
			// },
			handleCommand(val) {
				// this.multipleSelection = []
				// console.log(val)
				//当前页选中
				// console.log(this.multipleSelection, '当前页是否被选中')
				if ((val == 'a' || val == 'c') && !this.multipleSelection.length) {
					this.$message({showClose: true,
						type: 'error',
						message: '请选择门店'
					})
					return;
				}

				if (val == 'a' || val == 'c') {
					this.isCurrentPage = true;
				} else {
					this.isCurrentPage = false;
				}

				if (val == 'a' || val == 'b') {
					this.stockVisble = true;
					this.ruleForm.singleprice = ''
					//商品Id
					this.getSpecList(this.id, 1)
				} else {
					this.emailVisble = true;
				}
			},
			saveSeedType() {
				var obj = this.emailData[0];
				if (!obj.email && !obj.self &&!obj.city) {
					this.$message({showClose: true,
						type: 'error',
						message: '商品配送方式请至少开启1个'
					})
					return;
				} else {
					this.stopDialogShow = true
				}
			},
			savePriceChange() {
				//如果是修改供货价
				if (this.stockVisble) {
					//如果是默认规格
					if (this.isNormalSpec) {
						if (!this.ruleForm.singleprice && typeof(this.ruleForm.singleprice) == 'string') {
							this.$message({showClose: true,
								type: 'error',
								message: '请完善供货价'
							})
							return
						}
						if (this.ruleForm.singleprice > 100000000) {
							this.$message({showClose: true,
								type: 'error',
								message: '供货价不可超过100000000'
							})
							return
						}

						this.stopDialogShow = true

					} else {
						var list = this.specList.filter(item => {
							return item.SupplyPrice == '' && typeof(item.SupplyPrice) == 'string';
						})
						var limitlist = this.specList.filter(item => {
							return item.SupplyPrice > 100000000;
						})
						if (list.length > 0) {
							this.$message({showClose: true,
								type: 'error',
								message: '请完善供货价'
							})
							return
						}
						if (limitlist.length > 0) {
							this.$message({showClose: true,
								type: 'error',
								message: '供货价不可超过100000000'
							})
							return
						}

						this.stopDialogShow = true

					}
				}
				// this.multipleSelection = []
			},
			async sureSavePrice() {
				let params = {
					IsAllShop: !this.isCurrentPage,
					ProductId: this.id,
					ProductSpecSupplyList: this.specList.map(item => {
						return {
							ProductSpecId: item.Id,
							SupplyPrice: this.isNormalSpec ? this.ruleForm.singleprice : item.SupplyPrice
						}
					})
				}

				if (this.isCurrentPage) {
					params.ShopIds = this.multipleSelection.map(item => {
						return item.Id
					})
				}
				console.log(params)
				const res = await setshopproductspecsupplyprice(params);

				if (res.IsSuccess) {

					this.$message({showClose: true,
						type: 'success',
						message: '设置成功'
					})
					this.stopDialogShow = false;
					this.stockVisble = false;
					this.emailVisble = false;
					this.multipleSelection = []
					this.getList();
				} else {
					this.$message({showClose: true,
						type: 'error',
						message: res.Message
					})
					this.multipleSelection = []
				}
			},

			async sureSaveSeedType() {
				const res = await setshopproductsendtype({
					IsAllShop: !this.isCurrentPage,
					ShopIds: this.multipleSelection.map(item => {
						return item.Id
					}),
					ProductId: this.id,
					IsOpenSelfExtract: this.emailData[0].self,
					IsOpenExpress: this.emailData[0].email,
					IsOpenInCityDelivery: this.emailData[0].city,
				})
				if (res.IsSuccess) {
					this.$message({showClose: true,
						type: 'success',
						message: res.Message
					})
					this.stopDialogShow = false;
					this.stockVisble = false;
					this.emailVisble = false;
					this.multipleSelection = []
					this.getList();
				} else {
					this.$message({showClose: true,
						type: 'error',
						message: res.Message
					})
					this.multipleSelection = []
				}
			},

			async seedTypeChange(record, type) {
				try {
					const res = await shopproductsendtypeedit({
						ShopId: record.Id,
						Id: record.ShopProductConfigId,
						SendType: type
					})
				} catch (e) {

				} finally {
					this.getList();
				}
			}
		}
	}
</script>

<style>
</style>
